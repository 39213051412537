<template>
  <app-collapse
    :accordion="true"
    type="margin"
  >
    <app-collapse-item
      v-for="(category, index) in categories"
      :key="index"
      class="text-center"
      :title="category.name"
    >
      <PriceTable
        :title="'Price Algorithm'"
        :golog-cut="category.golog_cut"
        :display-mode="mode"
        :delivery-type="typeOfDelivery"
        :category-id="category.id"
        :price-list="filterPricesByDeliveryType(category.prices)"
        @getPrices="getPrices"
      />

      <b-row>
        <b-col
          cols="4"
          class="text-left"
        >
          <b-input-group
            prepend="Percentage (%)"
            class="mt-2"
            variant="primary"
          >
            <b-form-input
              v-model.number="category.tax_charge"
              :readonly="mode.value === 0 ? true : false"
              placeholder="Enter Tax Charge"
              type="number"
            />
            <b-input-group-append>
              <b-button
                :disabled="isLoading || mode.value === 0 ? true : false"
                variant="secondary"
                style="width:190px;"
                @click.prevent="handleSaveTaxCharge(category)"
              >
                <b-spinner
                  v-show="isLoading"
                  class="mr-50"
                  small
                />

                <feather-icon
                  v-show="!isLoading"
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span
                  class="align-middle"
                >
                  Save Tax Charge
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ taxChargeErrorMessage }}</small>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import PriceTable from '@/components/MyPriceChecker/PriceTable.vue'
import axios from '@axios'

export default {
  name: 'PriceCategoryCollapse',
  components: {
    AppCollapse,
    AppCollapseItem,
    PriceTable,
  },
  props: {
    categories: {
      default: () => [],
      type: Array,
    },
    typeOfDelivery: {
      default: 1,
      type: Number,
    },
    displayMode: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      taxChargeErrorMessage: '',
      isLoading: false,
      mode: this.displayMode,
    }
  },
  watch: {
    displayMode(val) {
      this.mode = val
    },
  },
  methods: {
    filterPricesByDeliveryType(priceList) {
      return priceList.filter(price => price.delivery_type === this.typeOfDelivery)
    },
    async handleSaveTaxCharge(category) {
      const { value: remarks } = await this.$swal({
        title: 'Remarks',
        text: 'Please enter remarks for the update',
        input: 'textarea',
        inputLabel: 'Enter remarks',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          header: 'mt-2',
          confirmButton: 'btn-primary',
          cancelButton: 'mr-2',
          input: 'mb-2',
          inputLabel: 'justify-content-start font-weight-bold',
          footer: 'mb-2',
        },
        /* eslint-disable-next-line */
        inputValidator: value => {
          if (!value) {
            return 'Remarks field is required. Write something!'
          }
        },
      })

      if (remarks) {
        try {
          this.isLoading = true
          const response = await axios.patch(`/price-categories/${category.id}`, {
            name: category.name,
            tax_charge: category.tax_charge,
            remarks,
          })
          if (response.status === 200) {
            this.taxChargeErrorMessage = ''
            this.$swal({
              icon: 'success',
              title: 'Tax Charge Updated',
              text: 'Successfully Updated The Tax Charge',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          if (response.status === 422) {
            this.taxChargeErrorMessage = response.data.errors?.tax_charge[0]
          }
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
        }
      }

      this.isLoading = false
    },
    getPrices() {
      this.$emit('getPrices')
    },
  },
}
</script>
