<template>
  <b-container fluid>
    <b-row>
      <b-col
        cols="6 mb-2"
        lg="2"
        class="ml-auto"
      >
        <b-dropdown
          id="mode"
          :text="mode.name"
          :variant="mode.color"
          class="w-100"
          split
          right
        >
          <b-dropdown-item
            v-for="(item, index) in dropdownItems"
            :key="index"
            :variant="item.color"
            @click.prevent="updateDisplayMode(item)"
          >
            <feather-icon :icon="item.icon" />
            <span class="pl-1">{{ item.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-tabs
          pills
        >
          <b-tab
            v-for="(delivery, index) in deliveryType"
            :key="index"
            class="text-center"
            :title="delivery.title"
            @click.prevent="setSelectedDeliveryType(delivery.type)"
          >
            <PriceCategoryCollapse
              :display-mode="mode"
              :type-of-delivery="delivery.type"
              :categories="priceCategories"
              @getPrices="getPrices"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="d-flex flex-column justifiy-content-center align-items-end"
      >
        <div class="py-2">
          <ValidationObserver
            ref="gologCutForm"
            v-slot="{ passes }"
          >
            <form @submit.prevent="passes(handleUpdateGologCut)">
              <ValidationProvider
                v-slot="{ errors }"
                vid="golog_cut"
                name="Type Of Market"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <div
                      class="input-group-text bg-primary text-white"
                    >
                      <span>Golog Cut</span>
                    </div>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="gologCut"
                    :readonly="mode.value === 0 ? true : false"
                    placeholder="Golog Cut"
                    type="number"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="isLoading || mode.value === 0 ? true : false"
                      variant="primary"
                      style="width:200px;"
                      type="submit"
                    >
                      <b-spinner
                        v-show="isLoading"
                        class="mr-50"
                        small
                      />

                      <feather-icon
                        v-show="!isLoading"
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span
                        class="align-middle"
                      >
                        Update Golog Cut
                      </span>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PriceCategoryCollapse from '@/components/MyPriceChecker/PriceCategoryCollapse.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

export default {
  components: {
    PriceCategoryCollapse,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const modes = [
      {
        value: 0,
        name: 'Read-Only Mode',
        icon: 'EyeIcon',
        color: 'primary',
      },
      {
        value: 1,
        name: 'Editing Mode',
        icon: 'EditIcon',
        color: 'success',
      },
    ]

    return {
      dropdownItems: modes,
      mode: {
        name: 'Display Mode',
        value: 0,
        color: 'primary',
      },
      deliveryType: [
        {
          title: 'SMARTTRUCK DELIVERY',
          type: 0,
        },
        {
          title: 'SELF-DELIVERY',
          type: 1,
        },
      ],
      priceCategories: [],
      selectedDeliveryType: 0,
      isLoading: false,
      gologCut: 0,
    }
  },
  created() {
    this.getPrices()
  },
  methods: {
    async getPrices() {
      this.resetEditingMode()
      const response = await axios.get('/price-checker')
      if (response.status === 200) {
        this.priceCategories = response.data.data
        this.gologCut = this.priceCategories[0].golog_cut
      }
    },
    resetEditingMode() {
      this.mode = {
        name: 'Read-Only Mode',
        value: 0,
        color: 'primary',
      }
    },
    setSelectedDeliveryType(type) {
      this.selectedDeliveryType = type
    },
    updateDisplayMode(item) {
      const user = getUserData()
      if (!user.ability.title.includes('High Level Management', 'IT Managers', 'IT Engineers')) {
        this.resetEditingMode()

        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'Editing Mode only allowed by IT Department only.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }
      this.mode = {
        name: item.name,
        value: item.value,
        color: item.color,
      }
    },
    async handleUpdateGologCut() {
      const { value: remarks } = await this.$swal({
        title: 'Remarks',
        text: 'Please enter remarks for the update',
        input: 'textarea',
        inputLabel: 'Enter remarks',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          header: 'mt-2',
          confirmButton: 'btn-primary',
          cancelButton: 'mr-2',
          input: 'mb-2',
          inputLabel: 'justify-content-start font-weight-bold',
          footer: 'mb-2',
        },
        /* eslint-disable-next-line */
        inputValidator: value => {
          if (!value) {
            return 'Remarks field is required. Write something!'
          }
        },
      })

      if (remarks) {
        this.isLoading = true
        const response = await axios.patch('/price-categories', {
          golog_cut: this.gologCut,
          remarks,
        })
        if (response.status === 422) {
          this.$refs.gologCutForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.$swal({
          icon: 'success',
          title: 'Golog Cut Updated',
          text: 'Successfully Updated The Golog Cut',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.getPrices()
        this.isLoading = false
      }

      this.isLoading = false
    },
  },
}
</script>
