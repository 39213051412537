<template>
  <div class="pt-1">
    <b-row>
      <b-col
        md="4"
        class="text-left"
      >
        <b-button
          variant="primary"
          :disabled="mode.value === 0 ? true : false"
          @click.prevent="handleAddNewPrice"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New Price</span>
        </b-button>
      </b-col>
      <b-col
        md="4"
        offset-md="4"
        class="text-right"
      >
        <b-button
          :disabled="isLoading || mode.value === 0 ? true : false"
          variant="success"
          @click.prevent="handleSubmitForm"
        >
          <b-spinner
            v-show="isLoading"
            class="mr-50"
            small
          />
          <feather-icon
            v-show="!isLoading"
            icon="SaveIcon"
            class="mr-50"
          />
          <span class="align-middle">Save Prices</span>
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'AddAndSaveButton',
  components: {
    BButton,
  },
  props: {
    isLoading: {
      default: false,
      type: Boolean,
    },
    displayMode: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mode: this.displayMode,
    }
  },
  watch: {
    displayMode(val) {
      this.mode = val
    },
  },
  methods: {
    handleAddNewPrice() {
      this.$emit('handleAddNewPrice')
    },
    handleSubmitForm() {
      this.$emit('handleSubmitForm')
    },
  },
}
</script>
