<template>
  <app-collapse :accordion="true">
    <app-collapse-item
      :title="title"
    >
      <b-card class="bg-light text-center p-0">
        <b-card-body class="text-center p-0">

          <table class="p-3 w-100">
            <thead>
              <th>
                <small><b>QUANTITY</b></small>
                <label>(RANGE OF BOX)</label>
              </th>
              <th>
                <small><b>UNIT PRICE</b></small>
                <label>(RM)</label>
              </th>
              <th v-show="deliveryType === 0">
                <small><b>PICKUP CHARGE</b></small>
                <label>(RM)</label>
              </th>
              <th>
                <small><b>B2BPRO</b></small>
                <label>(PRICE PER BOX)</label>
              </th>
              <th>
                <small><b>SMARTTRUCK</b></small>
                <label>(PRICE PER BOX)</label>
              </th>
              <th>
                <small><b>GOLOG</b></small>
                <label>(PRICE PER BOX)</label>
              </th>
              <th v-show="deliveryType === 0">
                <small><b>B2BPRO</b></small>
                <label>(PICKUP CHARGE)</label>
              </th>
              <!-- <th v-show="deliveryType === 0">
                <small><b>SMARTTRUCK</b></small>
                <label>(PICKUP CHARGE)</label>
              </th> -->
            </thead>
            <tbody>
              <tr
                v-for="(price, index) in filteredPrices"
                :key="index"
              >
                <td>
                  <b-form-input
                    v-model.number="price.quantity_start_range"
                    type="number"
                    class="inliwidth"
                    placeholder="0"
                    readonly
                  />
                  <span class="inliwidth tobetween"> to </span>
                  <b-form-input
                    v-model.number="price.quantity_end_range"
                    type="number"
                    class="inliwidth"
                    placeholder="0"
                    :readonly="mode.value === 0 ? true : false"
                  />
                </td>
                <td>
                  <b-input-group append="Per Box">
                    <b-form-input
                      v-model.number="price.unit_price"
                      placeholder="0"
                      type="number"
                      :readonly="mode.value === 0 ? true : false"
                    />
                    <!-- <template #append>
                      <b-form-select
                        v-model="price.unit_type"
                        :options="unitOptions"
                      />
                    </template> -->
                  </b-input-group>
                </td>
                <td v-show="deliveryType === 0">
                  <b-input-group append="Fixed">
                    <b-form-input
                      v-model.number="price.pickup_charge"
                      type="number"
                      placeholder="0"
                      :readonly="mode.value === 0 ? true : false"
                    />
                    <!-- <template #append>
                      <b-form-select
                        v-model="price.pickup_charge_type"
                        :options="unitOptions"
                      />
                    </template> -->
                  </b-input-group>
                </td>
                <td>
                  <b-form-input
                    placeholder="0"
                    :value="formatAmount(price.unit_price)"
                    disabled
                  />
                </td>
                <td>
                  <b-form-input
                    placeholder="0"
                    :value="getSmarttruckCut(price.unit_price)"
                    disabled
                  />
                </td>
                <td>
                  <b-form-input
                    placeholder="0"
                    :value="getGologCut(price.unit_price)"
                    disabled
                  />
                </td>
                <td v-show="deliveryType === 0">
                  <b-form-input
                    placeholder="0"
                    :value="formatAmount(price.pickup_charge)"
                    disabled
                  />
                </td>
                <!-- <td v-show="deliveryType === 0">
                  <b-form-input
                    placeholder="0"
                    :value="formatAmount(price.pickup_charge)"
                    disabled
                  />
                </td> -->
                <td
                  v-show="price.sort_order === lastItem && price.sort_order !== 0"
                  style="background-color: transparent"
                >
                  <b-button
                    variant="danger"
                    class="btn-icon rounded-circle"
                    :disabled="mode.value === 0 ? true : false"
                    @click.prevent="handleRemovePrice(index, price)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </td>
              </tr>
              <tr>
                <td
                  style="background-color: transparent"
                  :colspan="deliveryType === 0 ? '7' : '5'"
                >
                  <AddAndSaveButton
                    :display-mode="mode"
                    :is-loading="isSaveLoading"
                    @handleAddNewPrice="handleAddNewPrice"
                    @handleSubmitForm="handleSubmitForm"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-card>
      <b-alert
        v-for="(value, key) in errors"
        :key="key"
        v-height-fade.appear
        variant="primary"
        :show="errors.length > 0"
      >
        <div class="alert-body text-left">
          <span>{{ value }}</span>
        </div>
      </b-alert>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { formatAmount } from '@core/utils/filter'
import { BButton, BAlert } from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import axios from '@axios'
import AddAndSaveButton from './AddAndSaveButton.vue'

export default {
  name: 'PriceTable',
  components: {
    AppCollapse,
    AppCollapseItem,
    AddAndSaveButton,
    BButton,
    BAlert,
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    priceList: {
      default: () => [],
      type: Array,
    },
    categoryId: {
      default: null,
      type: Number,
    },
    deliveryType: {
      default: null,
      type: Number,
    },
    displayMode: {
      required: true,
      type: Object,
    },
    gologCut: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      unitOptions: [
        { value: 0, text: 'Per Box' },
        { value: 1, text: 'Fixed' },
      ],
      prices: [],
      smarttruckCutPerBox: 2,
      isSaveLoading: false,
      validationError: [],
      errors: [],
      mode: this.displayMode,
    }
  },
  computed: {
    filteredPrices() {
      return this.filterPrices()
    },
    lastItem() {
      const lastPrice = this.prices[this.prices.length - 1]
      return lastPrice.sort_order
    },
  },
  watch: {
    priceList(value) {
      if (value.length > 0) {
        this.prices = this.priceList
      }
    },
    displayMode(val) {
      this.mode = val
    },
  },
  mounted() {
    this.setPrices()
  },
  methods: {
    setPrices() {
      if (this.priceList.length > 0) {
        this.prices = this.priceList
      }
    },
    formatAmount(unitPrice) {
      return formatAmount(unitPrice)
    },
    getSmarttruckCut(unitPrice) {
      if (unitPrice === 0) {
        return formatAmount(0)
      }
      return formatAmount(unitPrice - this.gologCut)
    },
    getGologCut(unitPrice) {
      if (unitPrice === 0) {
        return formatAmount(0)
      }
      return formatAmount((unitPrice - (unitPrice - this.gologCut)))
    },
    async handleSubmitForm() {
      const { value: remarks } = await this.$swal({
        title: 'Remarks',
        text: 'Please enter remarks for the update',
        input: 'textarea',
        inputLabel: 'Enter remarks',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          header: 'mt-2',
          confirmButton: 'btn-primary',
          cancelButton: 'mr-2',
          input: 'mb-2',
          inputLabel: 'justify-content-start font-weight-bold',
          footer: 'mb-2',
        },
        /* eslint-disable-next-line */
        inputValidator: value => {
          if (!value) {
            return 'Remarks field is required. Write something!'
          }
        },
      })

      if (remarks) {
        try {
          this.isSaveLoading = true
          this.errors = []
          const finalPrice = this.prices.map(price => {
            const tempPrice = price
            tempPrice.del_flag = 0
            return tempPrice
          })
          const response = await axios.post('/prices', {
            prices: finalPrice,
            remarks,
          })
          if (response.status === 200) {
            this.$swal({
              icon: 'success',
              title: 'Prices Updated!',
              text: 'Successfully Updated The Price',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.refreshPrice()
          }
          if (response.status === 422) {
            /* eslint-disable-next-line */
            Object.entries(response.data.errors).forEach(([key, value]) => {
              if (!this.errors.includes(value[0])) {
                this.errors.push(value[0])
              }
            })
          }
          this.isSaveLoading = false
        } catch (error) {
          this.isSaveLoading = false
        }
      }

      this.isLoading = false
    },
    handleRemovePrice(index, row) {
      if (row.id === null) {
        this.prices.splice(index, 1)
        return
      }
      this.$swal({
        title: 'Are you sure to delete this price range?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.delete(`/prices/${row.id}`)
          if (response.status === 200) {
            this.prices.splice(index, 1)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      })
    },
    async handleAddNewPrice() {
      if (this.prices.length > 0) {
        const response = await axios.get(`/prices/restore?delivery_type=${this.deliveryType}&price_category_id=${this.categoryId}`)
        if (response.status === 200) {
          const { data } = { ...response.data }
          const lastPrice = data.find(price => price.sort_order === (this.lastItem + 1))
          if (typeof (lastPrice) !== 'undefined') {
            lastPrice.quantity_end_range = 0
            lastPrice.pickup_charge = 0
            lastPrice.unit_price = 0
            this.prices.push(lastPrice)
          } else {
            this.prices.push(this.newDefaultPrice())
          }
        } else {
          this.prices.push(this.newDefaultPrice())
        }
      } else {
        this.prices.push(this.newDefaultPrice())
      }
    },
    getLastPrice() {
      return this.prices[this.prices.length - 1]
    },
    isSequential() {
      if (this.prices.length > 0) {
        const quantities = this.prices.map(price => [price.quantity_start_range, price.quantity_end_range]).flatMap(x => x)
        return quantities.every((num, index) => index === quantities.length - 1 || num < quantities[index + 1])
      }
      return true
    },
    filterPrices() {
      if (this.prices.length > 0) {
        this.prices = this.prices.map((price, index) => {
          const temp = price
          if (index !== 0) {
            temp.quantity_start_range = this.prices[index - 1].quantity_end_range + 1
          }
          return temp
        })
      }
      return this.prices
    },
    hideRemoveButton() {
      if (this.prices.length > 0) {
        this.prices = this.prices.map(price => {
          const temp = price
          if (price.id === null) {
            temp.id = 0
          }
          return temp
        })
      }
      return this.prices
    },
    newDefaultPrice() {
      return {
        id: null,
        price_category_id: this.categoryId,
        delivery_type: this.deliveryType,
        quantity_start_range: this.prices.length > 0 ? this.getLastPrice().quantity_end_range + 1 : 1,
        quantity_end_range: 0,
        unit_price: 0,
        unit_type: 0,
        pickup_charge: 0,
        pickup_charge_type: 1,
        sort_order: this.prices.length > 0 ? this.getLastPrice().sort_order + 1 : 0,
        del_flag: 0,
      }
    },
    refreshPrice() {
      this.$emit('getPrices')
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-good-table.scss";
th {
  border: solid 10px #f6f6f6;
  border-style: solid none;
  padding: 10px;
  background-color: white;
  border-left-style: solid;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right-style: solid;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
td {
  border-left: solid 10px #f6f6f6;
  border-right: solid 10px #f6f6f6;
  padding: 10px;
  background-color: white;
}
.inliwidth {
  display: inline-block;
  width: 30%;
}
.tobetween {
  padding-left: 3%;
  padding-right: 3%;
}
</style>
